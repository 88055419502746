<template>
  <v-dialog
    v-model="value.dialog"
    width="60vw"
    eager
  >
    <v-card>
      <v-carousel
        :dark="$vuetify.theme.dark"
        :next-icon="false"
        :prev-icon="false"
        :light="!$vuetify.theme.dark"
        v-model="page">
        <v-carousel-item>
          <v-container>
            <create-rate
              ref="create-rate"
              @close-dialog="value.dialog = false"
            ></create-rate>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EmployeeDialog',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      page: 0,
    }
  },
  components: {
    createRate: () => import('@/views/admin/employees/rates/Create'),
  },
  watch: {
    value () {
      this.$emit('input', this.value)
    },
    'value.dialog': function (val) {
      if (val) {
        const { populateInputs } = this.$refs['create-rate']
        populateInputs(this.value)
      }
    },
  },
}
</script>
